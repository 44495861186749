import $ from 'jquery'
import router from '@/router';
import store from "@/state/store";
import {useNotification} from "@kyvg/vue3-notification";
import Swal from "sweetalert2";

const {notify} = useNotification();

export function maskMoneyTable(value) {
    var money = parseFloat(value);
    money = money.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
    return money;
}

export function formatMoney(id) {
    const value = document.getElementById(id).value;
    // Remove todos os caracteres que não sejam dígitos
    if (value === '') return;

    var cleanValue = value.replace(/\D/g, '');

    // Divide o valor em parte inteira e decimal
    var integerPart = cleanValue.substring(0, cleanValue.length - 2);
    const verify = integerPart.charAt(0);
    if (parseInt(integerPart) > 1 && verify[0] === '0') integerPart = integerPart.slice(1);
    if (integerPart === '' || integerPart == 0) integerPart = '0';
    var decimalPart = cleanValue.substring(cleanValue.length - 2);
    if (decimalPart.length < 2) decimalPart = '0' + decimalPart;

    // Adiciona separador de milhar
    integerPart = integerPart.replace(/(\d)(?=(\d{3})+$)/g, '$1.');

    // Formata o valor como moeda (adiciona símbolo de moeda e sinal de negativo)
    const format = 'R$ ' + integerPart + ',' + decimalPart;
    return document.getElementById(id).value = format;
}

export function toDecimal(value) {
    var cleanValue = value.replace(/\D/g, '');

    // Divide o valor em parte inteira e decimal
    var integerPart = cleanValue.substring(0, cleanValue.length - 2);
    const verify = integerPart.charAt(0);
    if (parseInt(integerPart) > 1 && verify[0] === '0') integerPart = integerPart.slice(1);
    if (integerPart === '' || integerPart == 0) integerPart = '0';
    var decimalPart = cleanValue.substring(cleanValue.length - 2);
    if (decimalPart.length < 2) decimalPart = '0' + decimalPart;

    // Adiciona separador de milhar
    integerPart = integerPart.replace(/(\d)(?=(\d{3})+$)/g, '$1.');

    return integerPart + ',' + decimalPart;
}

export function getUrl(session) {
    const values = JSON.parse(localStorage.getItem(session));
    let url = values.url + '/?';
    const params = values.params;
    const keys = Object.keys(values.params);

    for (let i = 0; i < keys.length; i++) {
        let result = params[keys[i]];
        url += `&${keys[i]}=${result}`;
    }

    return url;
}

export function startLoading(id, button) {
    document.getElementById('spinner').style.display = 'block';
    if (document.getElementById(id)) document.getElementById(id).style.opacity = '.2';
    if (button) document.getElementById(button).setAttribute('disabled', true);
}

export function endLoading(id, button) {
    setTimeout(function () {
        document.getElementById('spinner').style.display = 'none';
        if (document.getElementById(id)) document.getElementById(id).style.opacity = '1';
        if (document.getElementById(button)) document.getElementById(button).removeAttribute('disabled');
    }, 300)
}

export function formDataToJson(id) {
    var $form = $(`#${id}`);
    var unindexed_array = $form.serializeArray();
    var indexed_array = {};

    $.map(unindexed_array, function (n) {
        if (n['name'].indexOf("[]") != -1) {// é um multiselect
            let field = n['name'].replace("[]", "");
            if (indexed_array[field] == undefined) {
                indexed_array[field] = [];
            }
            indexed_array[field].push(n['value']);
        } else {
            indexed_array[n['name']] = n['value'];
        }
    });
    return indexed_array;
}

export function Forbidden(response) {
    console.log()
    if (response.response.status === 401 || response.response.status === 403) {
        localStorage.removeItem('token');
        router.push({
            name: 'login'
        })
    }

    notifyError(response.response.data.message);
}

export function ValidateForm(id) {

    const form = document.getElementById(id);
    form.classList.add('was-validated');
    const inputs = form.getElementsByClassName('form-control');
    for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].value === '' &&
            (inputs[i].getAttribute('required') === '' || inputs[i].getAttribute('required') === true)) {
            notifyError('Preencha todos os campos obrigatórios!')
            return false;
        }
    }

    return true;
}

export function notifyError(messsage) {
    notify({
        title: `<i class='bx bx-error'></i>` + messsage,
        position: 'bottom center',
        type: 'error',
        speed: 300,
        duration: 3000,
    });
}

export function notifySuccess(messsage) {
    notify({
        title: `<i class='bx bx-check-circle'></i>` + messsage,
        position: 'bottom center',
        type: 'success',
        speed: 300,
        duration: 3000,
    });
}

export function newOrder(order_by, session) {
    let obj = JSON.parse(localStorage.getItem(session))

    let icons = document.querySelectorAll('th i');
    for (let i = 0; i < icons.length; i++) {
        icons[i].style.opacity = '1';
    }

    if (obj.params.order_by === order_by) {
        let order = obj.params.order;
        order = order.charAt(0).toUpperCase() + order.slice(1);
        const id = order_by + order;

        document.getElementById(id).style.opacity = '0';

        obj.params.order = obj.params.order === 'asc' ? 'desc' : 'asc';
    } else {
        const id = order_by + 'Desc';

        document.getElementById(id).style.opacity = '0';

        obj.params.order = 'asc';
        obj.params.order_by = order_by;
    }

    localStorage.setItem(session, JSON.stringify(obj));

    const url = getUrl(session)
    store.dispatch('api/getApi', url);

}

export function loadAddress(i) {
    var cep = $('#zipCode' + i).val().replace(/\D/g, '');
    var validacep = /^[0-9]{8}$/;
    console.log(validacep);
    if (validacep.test(cep)) {
        $.getJSON("https://viacep.com.br/ws/" + cep + "/json/?callback=?", function (dados) {
            if (!("erro" in dados)) {
                $("#addressInput" + i).val(dados.logradouro);
                $("#uf" + i).val(dados.uf);
                $("#city" + i).val(dados.localidade);
                $("#neighborhood" + i).val(dados.bairro);
            }
        });
    }
}

export function maskCpfCnpj(value) {

    if (value.length <= 11) {
        return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
        return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }
}

export function getModalValues(data, form, button) {
    const keys = Object.keys(data);

    for (let i = 0; i < keys.length; i++) {
        const input = document.getElementById(keys[i]);
        if (input && data[keys[i]] != '') input.value = data[keys[i]];
    }
    endLoading(form, button);
}

export async function deleteElement() {
    return Swal.fire({
        title: "Você tem certeza?",
        text: "Seus dados serão removidos e não poderão mais ser recuperados.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
    }).then((result) => {
        return result;
    });
}

export function loadTable() {
    const tbody = document.querySelector('#table tbody');
    tbody.style.opacity = '.2'
    loadCard();
}

export function loadCard(id) {
    if (id) {
        const element = document.getElementById(id);
        element.style.opacity = '.2'
    }
    const placeholderElement = document.querySelector('.simplebar-placeholder');
    const width = parseInt(placeholderElement.offsetWidth) / 2 + 30;

    document.getElementById('spinner').style.display = 'block'
    document.getElementById('spinner').style.right = `calc(50% - ${width}px)`
}

export function endLoadTable() {
    const tbody = document.querySelector('#table tbody');
    if (tbody) tbody.style.opacity = '1'

    endLoadCard();
}

export function endLoadCard(id) {
    if (id) {
        const element = document.getElementById(id);
        element.style.opacity = '1'
    }
    document.getElementById('spinner').style.display = 'none'
    document.getElementById('spinner').style.right = `calc(50% - 2.5rem)`
}

/**
 *@param {string} dateTime data a ser convertida, formato ISO
 * @return {string} data convertida para formato pt-br com meses representados de forma simplificada
 */
export function convertDateText(dateTime) {
    let date = dateTime.split(' ');
    const months = [
        'Jan', 'Fev', 'Mar', 'Abr',
        'Mai', 'Jun', 'Jul', 'Ago',
        'Set', 'Out', 'Nov', 'Dez',
    ];

    date = date[0].split('-');
    const month = months[parseInt(date[1]) - 1];

    return `${date[2]} ${month} ${date[0]}`;
}

export function convertHour(dateTime) {
    let date = dateTime.split(' ');
    date = date[1].split('.');

    return date[0];
}

/**
 * @description Converter data do formato JS para string no formato dd Mês AAAA HH:mm
 * @param {string} date data no formato JS
 * @return {string} string da data convertida
 */
export function dateIso(date) {
    const dataObj = new Date(date);
    const dataFormatada = `${dataObj.getUTCFullYear()}-${dataObj.getUTCMonth() + 1}-${dataObj.getUTCDate() < 10 ? '0' + dataObj.getUTCDate() : dataObj.getUTCDate()} ${dataObj.getUTCHours() < 10 ? '0' + dataObj.getUTCHours() : dataObj.getUTCHours()}:${dataObj.getMinutes() < 10 ? '0' + dataObj.getMinutes() : dataObj.getMinutes()}`

    return `${convertDateText(dataFormatada)} ${convertHour(dataFormatada)}`;
}

export function showHide(show, hide, save) {
    if (save) {
        const card = document.getElementById(save);
        const height = card.offsetHeight;
        card.style.height = height + 'px';
    }
    $('#' + hide).hide();
    $('#' + show).show(300);
}
