import http from "@/http";
import {endLoadTable, Forbidden, notifySuccess} from "@/components/composables/functions";
import store from "@/state/store";

export const state = {
    listAll: {},
    pagination: {
        page: 0,
        limit: 25
    },
    showModalForm: false
}

export const mutations = {
    getList(state, newValue) {
        state.listAll = newValue
        state.pagination = {
            page: newValue.page,
            limit: newValue.limit
        }
    },

    getValues(state, newValue) {
        state.listAll = newValue
    },

    setPagination(state, value) {
        state.pagination = value;
    },

    changeShowModalForm(state, value) {
        state.showModalForm = value;
    }
}

export const actions = {
    // eslint-disable-next-line no-unused-vars
    init({state, dispatch}) {
        dispatch('validate')
    },

    getApi({commit}, url) {
        http.get(url, {
                headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
            })
            .then(response => {
                commit('getList', response.data);
                localStorage.setItem('token', response.data.token)
                endLoadTable();
                if (document.getElementById('spinnerTable')) document.getElementById('spinnerTable').style.display = 'none';
            })
            .catch(error => {
                endLoadTable();
                console.error('store - getApi: ', error);
                Forbidden(error);
            })
    },

// eslint-disable-next-line
    async insert({dispatch, commit}, data) {
        let url = data.url;
        let formData = data.formData;
        // if (formData.id > 0) {
        //     url += `/${formData.id}`;
        //     formData._method = 'PUT';
        // }
        return await http.post(url, formData, {
                headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
            })
            .then((response) => {
                if (data.url === 'usuarios') store.dispatch('auth/setConsultants');
                notifySuccess(response.data.message);
                return true;
            })
            .catch(error => {
                console.error('store - insert: ', error);
                Forbidden(error);
                return false;
            })
    },

    async delete({commit, dispatch}, data) {
        const session = JSON.parse(localStorage.getItem(data.session));
        const id = data.id;
        const url = `${session.url}/${id}`;
        return await http.delete(url, {
                headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
            })
            .then((response) => {
                // pegando os valores com js
                let start = document.getElementById('start').innerText;
                let partial = document.getElementById('partial').innerText;
                let count = document.getElementById('count').innerText;

                --partial;
                --count;

                // pegando os valores da store
                let newValues = state.listAll;

                // Se tive o valor total for menor que o inicio da lista,
                // diminuir o page e fazer nova busca
                if (partial === count && start > count) {
                    const newSession = session.params.page = newValues.page;
                    localStorage.setItem(session, newSession);
                    dispatch('getApi', session.url);
                    // se acabarem os itens desta página mas existirem mais páginas a frente,
                    //     fazer nova busca na mesma page
                } else if (partial < start && start < count) {
                    dispatch('getApi', session.url);
                    // se o total for 0, esvazia o objeto data para aparecer mensagem na tabela
                } else if (count === 0) {
                    newValues.data = {};
                    commit('getValues', newValues);
                    --start;
                    document.getElementById('start').innerText = start;
                }

                document.getElementById('partial').innerText = partial;
                document.getElementById('count').innerText = count;
                document.getElementById('line' + id).style.display = 'none';

                console.log(response);

                notifySuccess(response.data.message);
            })
            .catch(error => {
                console.error('store - delete: ', error);
                Forbidden(error);
            })
    }
}
