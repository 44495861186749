// import store from "@/state/store";

export default [
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/account/login"),
        meta: {
            title: "Login",
            // beforeResolve(routeTo, routeFrom, next) {
            //     if (store.getters["auth/loggedIn"]) {
            //         next({name: "dashboard"});
            //     } else {
            //         next();
            //     }
            // },
        },
    },
    {
        path: "/esqueceu-senha",
        name: "forgot-password",
        component: () => import("@/views/account/forgot-password.vue"),
        meta: {
            title: "Recuperar Senha",
        },
    },
    {
        path: "/recuperar-senha/:token",
        name: "change-password",
        component: () => import("@/views/account/change-password.vue"),
        meta: {
            title: "Resetar Senha",
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import("@/views/account/logout"),
        meta: {
            title: "Logout",
        },
    },
    {
        path: "/",
        name: "dashboard",
        meta: {
            title: "Dashboard",
            authRequired: true,
        },
        component: () => import("@/views/dashboard/index"),
    },
    {
        path: "/usuarios",
        name: "users",
        meta: {
            title: "Usuários",
            authRequired: true,
        },
        component: () => import("@/views/users/users"),
    },
    {
        path: "/acessos",
        name: "access",
        meta: {
            title: "Acessos",
            authRequired: true,
        },
        component: () => import("@/views/access/access.vue"),
    },
    {
        path: "/leads",
        name: "leads",
        meta: {
            title: "Leads",
            authRequired: true,
        },
        component: () => import("@/views/leads/leads.vue"),
    },
    {
        path: "/leads/:id",
        name: "lead_detail",
        meta: {
            title: "Detalhes do Lead",
            authRequired: true,
        },
        component: () => import("@/views/leads/details.vue"),
    },
    {
        path: "/leads/cadastrar/:id?",
        name: "lead_register",
        meta: {
            title: "Cadastrar Lead",
            authRequired: true,
        },
        component: () => import("@/views/leads/register.vue"),
    },
    {
        path: "/clientes",
        name: "clients",
        meta: {
            title: "Clientes",
            authRequired: true,
        },
        component: () => import("@/views/clients/clients.vue"),
    },
    {
        path: "/clientes/documentos/:id",
        name: "clients_docs",
        meta: {
            title: "Documentos",
            authRequired: true,
        },
        component: () => import("@/views/clients/docs.vue"),
    },
    {
        path: "/agenda",
        name: "calendar",
        meta: {
            title: "Agenda",
            authRequired: true,
        },
        component: () => import("@/views/calendar/calendar.vue"),
    },
];
